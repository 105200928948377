$_button-imported: false !default;

@if ( $_button-imported == false ) {

    %appearance_button {
        color: $button-text;
        background-color: $button-bg;
        border-color: $button-border;
        @include linear-gradient( $button-gradient );
    }
    %appearance_hovered-button {
        color: $button-hovered-text;
        background-color: $button-hovered-bg;
        border-color: $button-hovered-border;
        @include linear-gradient( $button-hovered-gradient );
    }
    %appearance_pressed-button {
        color: $button-pressed-text;
        background-color: $button-pressed-bg;
        border-color: $button-pressed-border;
        @include linear-gradient( $button-pressed-gradient );
        @include box-shadow( $button-pressed-shadow );
    }
    %appearance_focused-button {
        @include box-shadow( $button-focused-shadow );
    }

    %appearance_primary-button {
        color: $primary-button-text;
        background-color: $primary-button-bg;
        border-color: $primary-button-border;
        @include linear-gradient( $primary-button-gradient );
    }
    %appearance_hovered-primary-button {
        color: $primary-button-hovered-text;
        background-color: $primary-button-hovered-bg;
        border-color: $primary-button-hovered-border;
        @include linear-gradient( $primary-button-hovered-gradient );
    }
    %appearance_pressed-primary-button {
        color: $primary-button-pressed-text;
        background-color: $primary-button-pressed-bg;
        border-color: $primary-button-pressed-border;
        @include linear-gradient( $primary-button-pressed-gradient );
    }

    %appearance_toolbar {
        color: $toolbar-text;
        background-color: $toolbar-bg;
        border-color: $toolbar-border;
        @include linear-gradient( $toolbar-gradient );
    }

    $_button-imported: true;

}


@mixin appearance_button { @extend %appearance_button };
@mixin appearance_hovered-button { @extend %appearance_hovered-button };
@mixin appearance_pressed-button { @extend %appearance_pressed-button };
@mixin appearance_focused-button { @extend %appearance_focused-button };

@mixin appearance_primary-button { @extend %appearance_primary-button };
@mixin appearance_hovered-primary-button { @extend %appearance_hovered-primary-button };
@mixin appearance_pressed-primary-button { @extend %appearance_pressed-primary-button };

@mixin appearance_toolbar { @extend %appearance_toolbar };
