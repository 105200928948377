﻿@import "variables"; 
@import "../../../libs/bootstrap-v4/4.3.1/scss/progress";


#pwvisible {
    float: right;


    .pwhide {
        display: none;
        color: theme-color("danger");
    }

    .pwshow {
        color: $text-muted;
    }
}

.progress, .progress-bar {
    border-radius: $progress-border-radius;
}


#newpassword {

    &.showpw {
        .pwhide {
            display: inline;
        }

        .pwshow {
            display: none;
        }
    }

    .feedback {
        font-size: $font-size-sm;
        margin-top: 5px;
    }

    .warning .feedback {
        color: theme-color('warning');
    }

    .danger .feedback {
        color: theme-color('danger');
    }
}

.passwordtips {
    margin-top: 2rem;
    color: $text-muted;
    font-size: $font-size-sm;

    .title {
        text-transform: uppercase;
        letter-spacing: .05rem;
    }

    ul {
        margin-bottom: 0;
        padding-left: 15px;
    }
}