﻿@import "variables";


.k-dropdown {
    background-color: #fff;
    border-width: 1px;
    width: 100%;

    .k-dropdown-wrap {
        border: 0;
    }

    .k-input {
        border: solid 1px $gray-400;
        line-height: 1.5;
        /*height: 100%;*/
        min-height: 45px;
    }

    .k-icon {
        font-size: 2rem;
        height: auto;
        width: auto;
    }

    .k-select {
        display: inline-block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 0.7rem .75rem;
        font-size: 1.9rem;
        background-color: $gray-400;
        background-color: ButtonFace;
        width: 45px;
        color: #000;
    }
}

.k-dropdown.chromeless {
    border: 0;
    justify-content: center;

    .k-dropdown-wrap {
        border: 0;
        background: transparent;
        width: auto;
    }

    .k-input {
        border: 0;

        &:after {
            content: "\f0d7";
            padding: 0.3rem 0 0 0.5rem;
            font-family: "Font Awesome 5";
            font-weight: 900;
            display: flex;
            align-self: end;
        }
    }

    .k-select {
        display: none;
        border: 0;
        background: transparent;
        width: auto;
    }

    .k-state-disabled {
        opacity: 1;

        .k-input:after {
            display: none;
        }

        .k-select {
            display: none;
        }
    }
}
