@include exports( "common/layout" ) {

    // Layout
    .k-reset {
        margin: 0;
        padding: 0;
        border-width: 0;
        outline: 0;
        text-decoration: none;
        font: inherit;
        list-style: none;
    }


    // Layout
    .k-widget {
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-size: $font-size;
        line-height: $line-height;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        &:disabled,
        &[disabled] {
            @include disabled;
        }
    }
    table.k-widget { display: table; }

    kendo-sortable,
    .k-display-block {
        display: block;
    }


    // Overlay
    .k-overlay {
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
    }


    // RTL
    .k-rtl {
        direction: rtl;
    }


    // Float wrap
    .k-floatwrap::after,
    .k-grid-toolbar::after,
    .k-slider-items::after {
        content: "";
        display: block;
        clear: both;
    }


    // Links
    .k-link,
    .k-link:hover {
        color: inherit;
        text-decoration: none;
    }

    // Outline
    .k-content {
        outline: 0;
    }


    // Box sizing
    .k-block {
        box-sizing: border-box;
    }


    // Centering
    .k-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    // Off-screen container used during PDF export
    .k-pdf-export-shadow {
        position: absolute;
        overflow: hidden;
        left: -15000px;
        width: 14400px;
    }

    //PDF export icons fix
    .kendo-pdf-hide-pseudo-elements::before,
    .kendo-pdf-hide-pseudo-elements::after {
        // sass-lint:disable-block no-important
        display: none !important;
    }

    // Flipping
    .k-flip-h { transform: scaleX(-1); }
    .k-flip-v { transform: scaleY(-1); }
    .k-flip-h.k-flip-v { transform: scale(-1, -1); }


    // Dirty indicator
    .k-dirty {
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border-width: 3px;
        border-style: solid;
        position: absolute;
        top: 0;
        right: 0;
    }


    // Hiding
    .k-hidden {
        display: none !important; // sass-lint:disable-line no-important
    }


    // Horizontal rule
    .k-hr {
        margin: ($spacer-y / 2) auto;
        padding: 0;
        height: 0;
        border-width: 1px 0 0;
        border-style: solid;
        display: block;
        float: none;
        clear: both;
    }

}


// virtual scrollbar
.k-scrollbar {
    position: absolute;
    overflow: scroll;
}

.k-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 17px; /* scrollbar width */
    height: 100%;
    overflow-x: hidden;
}

.k-touch-scrollbar {
    display: none;
    position: absolute;
    z-index: 200000;
    height: 8px;
    width: 8px;
    border: 1px solid #8a8a8a;
    background-color: #858585;
}

@include exports( "common/theme" ) {

    // Widget
    .k-widget {
        @include appearance( widget );

        ::selection {
            background-color: $accent;
            color: $accent-contrast;
        }
    }


    // Panels
    .k-content {
        @include appearance( panel );
    }
    .k-block {
        @extend .k-widget;
        @include border-radius();
        padding: $panel-padding-y $panel-padding-x;

        > .k-header {
            @include appearance( header );
            margin: #{-$panel-padding-y} #{-$panel-padding-x} $panel-padding-y;
            padding: $header-padding-y $popup-padding-x;
        }

        ::selection {
            background-color: $accent;
            color: $accent-contrast;
        }
    }

    .k-info-colored {
        color: #50607f;
        border-color: #d0d9df;
        background-color: #f0f9ff;
    }

    .k-success-colored {
        color: #507f50;
        border-color: #d0dfd0;
        background-color: #f0fff0;
    }

    .k-error-colored {
        color: #7f5050;
        border-color: #dfd0d0;
        background-color: #fff0f0;
    }

    .k-shadow {
        box-shadow: $popup-shadow;
    }

    .k-inset {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .03), inset 0 4px 5px 0 rgba(0, 0, 0, .04);
    }


    // Header
    .k-header {
        // @include appearance( header );
    }
    .k-header.k-state-hover {
        // @include appearance( hovered-header );
    }
    .k-header.k-state-selected {
        // @include appearance( selected-header );
    }


    // Link
    .k-link,
    .k-link:hover {
        // color: $base-text;
    }


    // Hover state
    .k-state-hover,
    .k-state-hover:hover {
        // background-color: $hovered-bg;
        // border-color: $hovered-border;
        // color: $hovered-text;
    }


    // Selected state
    .k-state-selected {
        color: $selected-text;
        background-color: $selected-bg;
        border-color: $selected-border;
    }


    // TODO: focused state
    // Focused focused state
    .k-state-focused {
        // box-shadow: $focused-item-shadow;
    }


    // TODO: what is highlight state?
    // Highlight state
    .k-state-highlight {
        // background-color: saturate($base-bg, 4%);
        // border-color: $accent;
    }


    // TODO: what is active state?
    // Active state
    .k-state-active {
        // background-color: $widget-bg;
    }


    // Disabled state
    .k-disabled,
    .k-state-disabled {
        @include disabled;


        .k-link,
        .k-button {
            cursor: default;
            outline: 0;
        }


        // Nested disabled
        [disabled],
        .k-disabled,
        .k-state-disabled {
            opacity: 1;
            filter: grayscale(0);
        }
    }


    // Error-state
    .k-state-error {
        border-style: ridge;
    }


    // TODO: what is smpty state?
    // Empty state
    .k-state-empty {
        font-style: italic;
    }


    // Dirty indicator
    .k-dirty {
        border-color: $error $error transparent transparent;
    }


    // Horizontal rule
    .k-hr {
        border-color: $panel-border;
    }

}


@include exports( "common/resize" ) {

    // Resize handle
    .k-resize-handle,
    .k-resize-hint {
        position: absolute;
        border-color: inherit;
    }
    .k-resize-handle {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .k-resize-handle::before {
        content: "";
        border: 0 solid;
        border-color: inherit;
    }
    .k-resize-n {
        width: 100%;
        height: 6px;
        flex-direction: row;
        left: 0;
        top: -3px;
        cursor: n-resize;
    }
    .k-resize-s {
        width: 100%;
        height: 6px;
        flex-direction: row;
        left: 0;
        bottom: -3px;
        cursor: s-resize;
    }
    .k-resize-w {
        width: 6px;
        height: 100%;
        flex-direction: col;
        top: 0;
        left: -3px;
        cursor: w-resize;
    }
    .k-resize-e {
        width: 6px;
        height: 100%;
        flex-direction: col;
        top: 0;
        right: -3px;
        cursor: w-resize;
    }

}


@include exports( "common/lines" ) {

    // Lines
    .k-line {
        background-color: currentColor;
        border-color: currentColor;
    }
    .k-line-h,
    .k-line-v {
        position: absolute;
    }
    .k-line-h { height: 2px; }
    .k-line-v { width: 2px; }


    // Arrows
    .k-arrow-e,
    .k-arrow-w {
        width: 0;
        height: 0;
        border: 5px solid transparent;
        position: absolute;
        top: -4px;
    }
    .k-arrow-e {
        border-left-color: currentColor;
        right: -6px;
    }
    .k-arrow-w {
        border-right-color: currentColor;
        left: -6px;
    }

}


@include exports( "common/marquee" ) {

    .k-marquee {
        position: absolute;
        z-index: 100000;
    }
    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .k-marquee-color {
        color: $selected-text;
        background-color: $selected-bg;
        border-color: $selected-border;
        opacity: .6;
    }
    .k-marquee-text {
        color: $selected-text;
    }

}


@include exports( "common/loading" ) {

    // Loading indicator
    .k-loading {
        width: 64px;
        height: 64px;
        display: block;

        .animate {
            animation: loading 2s infinite linear;
        }
    }

    @keyframes loading {
        0% {
            stroke-dasharray: 0 251;
            stroke-dashoffset: 502;
        }

        50% {
            stroke-dasharray: 250 1;
        }

        100% {
            stroke-dasharray: 0 251;
            stroke-dashoffset: 0;
        }
    }


    // Loading mask
    .k-loading-mask,
    .k-loading-image,
    .k-loading-color {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .k-loading-mask {
        z-index: 100;

        &.k-opaque {
            .k-loading-color {
                opacity: 1;
            }
        }
    }
    .k-loading-text {
        text-indent: -4000px;
        text-align: center;
        position: absolute;
    }
    .k-loading-image {
        z-index: 2;
    }
    .k-loading-color {
        background-color: $widget-bg;
        opacity: .3;
    }

    // Loading indicator
    .k-i-loading {
        position: relative;
        background-color: transparent;
        box-sizing: border-box;
        color: currentColor;
    }

    .k-i-loading::before,
    .k-i-loading::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        content: "";
        box-sizing: inherit;
        border-radius: 50%;
        border-width: .03em;
        border-style: solid;
        border-color: currentColor;
        border-top-color: transparent;
        border-bottom-color: transparent;
        background-color: transparent;
    }
    .k-icon.k-i-loading::before,
    .k-icon.k-i-loading::after {
        content: "";
    }

    .k-i-loading::before {
        margin-top: -.5em;
        margin-left: -.5em;
        width: 1em;
        height: 1em;
        animation: k-loading-animation .7s linear infinite;
    }

    .k-i-loading::after {
        margin-top: -.25em;
        margin-left: -.25em;
        width: .5em;
        height: .5em;
        animation: k-loading-animation reverse 1.4s linear infinite;
    }

    .k-loading-image::before {
        @extend .k-i-loading::before;
    }

    .k-loading-image::after {
        @extend .k-i-loading::after;
    }

    .k-loading-image::before,
    .k-loading-image::after {
        border-width: .015em;
        font-size: 4em;
    }

    @keyframes k-loading-animation  {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}


@include exports( "common/dragclue" ) {

    // Layout
    .k-drag-clue {
        padding: $padding-y-sm $padding-x-sm;
        border-width: 1px;
        border-style: solid;
        font-size: $font-size-sm;
        white-space: nowrap;
        overflow: hidden;
        cursor: default;
    }
    .k-drag-status {
        margin-right: .4ex;
    }


    // Theme
    .k-drag-clue {
        @include appearance( selected-node );
    }

}


@include exports( "common/reordercue" ) {

    // Layout
    .k-reorder-cue {
        position: absolute;

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 0;
            border: 3px solid transparent;
            position: absolute;
            transform: translateX(-50%);
        }

        &::before {
            border-bottom-width: 0;
            border-top-color: currentColor;
            top: -4px;
        }
        &::after {
            border-top-width: 0;
            border-bottom-color: currentColor;
            bottom: -4px;
        }
    }

}