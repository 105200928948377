﻿@import "variables";

@import "../../../libs/bootstrap-v4/4.3.1/scss/pagination";

.pagination {
    justify-content: center;
    margin-bottom: 4rem;

    .page-item {
        margin-right: 1rem;

        &.active {
            .page-link {
                background-color: theme-color('info');
                border-color: theme-color-level('info', 3);
            }
        }
    }

    .page-link {
        font-size: $font-size-base * 1.2;
    }
}