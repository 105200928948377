@include exports('popup/layout') {

    .k-popup {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin: 0;
        padding: $popup-padding-y $popup-padding-x;
        border-width: 1px;
        border-style: solid;
        font-size: $font-size;
        line-height: $line-height;
        box-sizing: content-box;

        .k-item {
            cursor: pointer;
            outline: none;
        }

        [dir='rtl'] & {
            .k-list-filter {
                > .k-icon {
                    left: $padding-x * 2;
                    right: auto;
                }
            }

            .k-list-optionlabel {
                text-align: right;
            }
        }
    }
    .k-animation-container {
        @include border-bottom-radius-only();

        .k-popup {}
    }
    // Virtual list -> K1 - virtuallist.less
    .k-popup .k-item.k-first {
        position: relative;
    }
    .k-popup > .k-group-header,
    .k-popup > .k-virtual-wrap > .k-group-header {
        padding: $list-item-padding-y $list-item-padding-x;
        min-height: $line-height-em;
        white-space: normal;
        transition: $transition;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
    .k-popup .k-list .k-item > .k-group {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 .5em;
        font-size: $font-size * .714;
        line-height: 1.5;
        text-transform: uppercase;
    }
    .k-popup .k-list .k-item > .k-group::before {
        display: block;
        content: " ";
        border-width: 7px;
        border-style: solid;
        position: absolute;
        left: -14px;
        bottom: 0;
    }
    .k-group-header+div>.k-list>.k-item.k-first::before {
        content: " ";
        display: block;
        border-top-width: 1px;
        border-top-style: solid;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
    }
}


@include exports( "list/layout" ) {

    // Layout
    .k-list-scroller {
        position: relative;
        overflow: auto;
    }
    .k-list {
        height: auto;

        > .k-item {
            display: flex;
            align-items: center;
            align-content: center;

            > .k-icon {
                align-self: center;
                margin-right: $padding-x;
            }
        }
    }

    .k-list .k-item,
    .k-list-optionlabel {
        padding: $list-item-padding-y $list-item-padding-x;
        min-height: $line-height-em;
        line-height: $line-height-em;
        white-space: normal;
    }
    .k-list-optionlabel {
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }


    // Filter
    .k-list-filter {
        display: block;
        position: relative;
        padding: $padding-x;
        height: add-three(4 * $padding-y, $form-line-height-em, 2 * $button-padding-y);
        box-sizing: border-box;

        > .k-textbox {
            // remove the !important declaration when https://github.com/telerik/kendo-ui-core/issues/2617 is fixed
            width: 100% !important; // sass-lint:disable-block no-important
            box-sizing: border-box;
            padding-right: add-two($icon-size, $padding-x);
        }

        > .k-icon {
            position: absolute;
            right: $padding-x * 2;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .k-dropdown-button .k-popup {
        .k-item {
            cursor: pointer;
        }
    }

    //SplitButton
    //TODO: Wait for final design + revise
    .k-split-button,
    .k-dropdown-button {
        .k-list .k-item:focus {
            outline: none;
        }
    }

}




@include exports( "listcontainer/layout" ) {

    .k-list-container {

        .k-button {
            @include border-radius( 0 );
            padding: $list-item-padding-y $list-item-padding-x;
            border-width: 0;
            color: inherit;
            background-color: transparent;
            background-image: none;
            line-height: inherit;
            display: flex;
            justify-content: flex-start;

            .k-icon {
                margin: 0 $icon-spacing 0 0;
            }
        }

        .k-nodata {
            .k-button {
                display: inline-flex;
                border-width: 1px;
                @include appearance( button );
            }
        }
    }

}
