﻿@import "variables";


.main-nav {
    /*&.fixed-top + main.container {
        padding-top: 90px;
    }*/
    .nav-link {
        font-weight: bold;
        padding: 0.5rem 1.5rem !important;
        font-size: 1.5rem;
    }

    .navbar-brand {
        height: 75px;
        width: 139px;
    }

    .logo {
        height: 100%;

        img {
            height: 65px;
            margin-top: 3px;
        }
    }

    .dropdown-menu {
        background: $main-nav-bg-darker;

        a {
            color: $main-nav-color;

            @media (hover:hover) {
                &:hover {
                    background-color: $main-nav-bg !important;
                    color: color-yiq($main-nav-bg);
                }
            }
        }
    }

    .dropdown-divider {
        border-top-color: $main-nav-bg;
    }


    .navbar-text {
        margin: 0;
        @include font-size($h2-font-size);

        @include media-breakpoint-only(sm) {
            margin-right: $grid-gutter-width / 2;
        }
    }

    .navbar-toggler {
        padding: 0;
        border: 0;
    }
    // Starting values for when the burger is collapsed/menu not shown
    // ie, this just gives the 3 bars.
    .navbar-toggler {
        &.collapsed {

            #brgr_t, #brgr_b {
                transform: rotate( 0deg ); // to prevent pixelatedness
                width: 32px;
            }

            #circle {
                stroke-dasharray: 1 100 30 300; // 32 is the hamburger size
                stroke-dashoffset: 101; // setting up a gap to swipe in from the left
                stroke-opacity: 1;
            }
        }
    }
}

.main-nav.solid {
    background-color: $main-nav-bg;

    picture.dark {
        display: none;
    }

    .nav-link {
        color: $main-nav-color;
        font-weight: normal;
        padding: 2.2rem 1.5rem !important;

        @media (hover:hover) {
            &:hover {
                color: color-yiq($main-nav-bg);
                background-color: $main-nav-bg-darker;
            }
        }
    }

    .dropdown-toggle[aria-expanded="true"] {
        color: color-yiq($main-nav-bg);
        background: $main-nav-bg-darker;
    }

    .dropdown-divider {
        border-top-color: $main-nav-bg;
    }

    .navbar-text {
        color: $main-nav-color;
    }
    // Starting values for when the burger is collapsed/menu not shown
    // ie, this just gives the 3 bars.
    .navbar-toggler {
        background-color: $main-nav-bg-darker;
        color: color-yiq($main-nav-bg);

        .line {
            background: color-yiq($main-nav-bg-darker);
        }

        #circle {
            stroke: color-yiq($main-nav-bg-darker);
        }

        &.collapsed {
            background-color: transparent;


            .line {
                background: $main-nav-color;
            }

            #circle {
                stroke: $main-nav-color;
            }

            @media (hover:hover) {
                &:hover {
                    background-color: $main-nav-bg-darker !important;
                    color: color-yiq($main-nav-bg);

                    .line {
                        background: color-yiq($main-nav-bg-darker);
                    }

                    #circle {
                        stroke: color-yiq($main-nav-bg-darker);
                    }
                }
            }
        }
    }
}

.burger {
    /*float: right;*/
    padding: 0;
    margin: 0;
    /*width: $nav-burger-width;*/
    /*height: 58px;*/
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    touch-action: none;
    padding-left: 9px;



    .line {
        position: absolute;
        display: block;
        height: 2px;
        width: 19px;
        border-radius: 2px;
        left: 22px;
        transform-origin: 0 0;
    }

    #brgr_t {
        top: 28px;
        transform: translateY(3px) translateX(13px) rotate(45deg);
    }

    #brgr_m {
        top: 28px;
    }

    #brgr_b {
        top: 48px;
        transform: translateY(-3.5px) translateX(11.5px) rotate(-45deg);
    }

    svg {
        margin-top: 10px;
    }

    path, line {
        background: theme-color('default');
        stroke-linecap: round;
        stroke-width: 2px;
    }

    line, path, .line {
        //transition: transform $nav-burger-animation * (2/3) cubic-bezier(.8, -1.1, .5, 1.9); // x to = animation
        transition: transform $nav-burger-animation linear;
    }
    // Animations ***************************************
    // The circle animation is done by changing two things:
    // - a new 100px gap is swiped in from the left by changing offset
    // - the 'burger' dash is increased from 32px to 190px
    #circle {
        stroke-dasharray: 1 100 190 300;
        stroke-dashoffset: 2;
        transition: all $nav-burger-animation ease; // - to () animation
        stroke-opacity: 0.3;
    }
}


@include media-breakpoint-only(xs) {
    .main-nav {
        .navbar-text {
            display: none;
        }
    }
}


@include media-breakpoint-down(sm) {
    .main-nav {
        .navbar-nav {
            background-color: $main-nav-bg-darker;
            border-bottom: solid 5px theme-color('success');
            padding: 1rem 0;


            .nav-item {
                margin: 0 1rem;
            }

            .nav-link {
                color: #fff;
            }
        }

        .navbar-collapse {
            /*margin: 1rem -1rem -1rem -1rem;
            flex-grow: 1;*/
            position: absolute;
            z-index: 1021;
            top: 74px;
            left: 0;
            right: 0;
        }

        .nav-link {
            padding: 1.3rem 1.5rem !important;

            @media (hover:hover) {
                &:hover {
                    background-color: $main-nav-bg;
                }
            }
        }

        .dropdown-menu {
            box-shadow: none;
            margin-top: -1rem;

            a {
                padding-left: 2.5rem;
            }
        }

        .dropdown-divider {
            display: none;
        }
    }
}