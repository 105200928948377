﻿@import "variables";


/*.coming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
}

.coming-soon .overlay {
    background-color: #244854;
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(10px);
    opacity: 0.7;
    z-index: 10000;
    display: flex;
}

.coming-soon .overlay:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
}

.coming-soon .middle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.coming-soon h1 {
    color: white;
    font-size: 3rem;
    filter: drop-shadow(2px 2px 1px black);
    margin-top: 2rem;
}

.coming-soon picture {
    filter: drop-shadow(2px 2px 1px black);
}*/

.preview-badge {
    position: fixed;
    //top: 0;
    //left: calc(50% - 80px);
    padding: 0rem 2.5rem 0.2rem 2.5rem;
    background-color: #ff6a00;
    color: white;
    z-index: 10000;
    letter-spacing: 3px;
    font-variant: small-caps;
    font-size: 2rem;
    transform: rotate(90deg);
    right: -47px;
    top: 180px;
}
