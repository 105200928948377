﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/buttons";



button:focus {
    outline: 0;
}


.btn {
    padding: 1rem 1.5rem;

    i {
        font-weight: bold;
    }
}

.btn-sm {
    padding: 0.5rem;
}

/*.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
    padding: 11px 15px;
}*/

/*.btn-primary {
    //TODO  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}*/

.btn-pad {
    margin: 7px;   
}


.btn-link {
    padding: 0;
}