﻿@import "variables";



#consent {
    background-color: theme-color('dark');
    position: fixed;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 1rem 2rem;
    z-index: 99999999;

    .msgs {
        display: block;

        strong {
            font-size: 2rem;
        }

        p {
            margin: 0.2rem 0 1rem 0;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    .controls {
        display: block;
        text-align: right;

        a {
            color: #fff;
            background: theme-color-level('success', 2);
            border: solid 1px theme-color-level('success', 3);
            padding: 1rem;
            display: inline-block;
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;

        .msgs {
            flex: 1 1 auto;
            padding-right: 5rem;
        }

        .controls {
            flex: 0 0 auto;
            text-align: inherit;
            justify-content: center;
            align-self: center;
        }
    }
}


