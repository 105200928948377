﻿@import "_variables";



.hero {
    h2 {
        font-size: $font-size-base * 2.15;
        margin-bottom: 2rem;
    }

    .date {

    }

    .actions {
        display: flex;
        margin-top: 3rem;
        align-content: flex-end;
        font-weight: 600;
    }

    @include media-breakpoint-up(md) {
        .details {
            p {
                width: 53%;
            }
        }
    }
}
