﻿@import "variables";


.tldlgo {
    display: inline-block;

    /*border: solid 1px theme-color('info');
    border-bottom-right-radius: 10px;*/

    font-weight: bold;
    font-size: $font-size-lg;
    padding: 10px;
}



.tld-promo {
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    .tile {
        margin-bottom: 4rem;
    }

    .tile-body {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-only(xs) {
        .tile:last-child {
            margin-bottom: 1rem;
        }
    }

    header {
        display: none;
    }

    &.with-promo {
        header {
            display: block;
            text-align: center;
            margin-bottom: 0.5rem;

            span {
                font-size: $font-size-sm;
                background-color: theme-color('success');
                color: #fff;
                padding: 0.2rem 1rem;
            }

            span.save {
                background-color: $gray-700;
            }
        }
    }
    // logo and price wrapper
    .d-flex {
        width: 100%;
        justify-content: center;
        align-items: center;
        flex: 0 0 7rem;
    }

    .logo {
        text-align: right;
        margin-right: 1rem;

        img {
            max-height: 45px;
        }
    }

    .pricing {
        margin-left: 1rem;
        /*align-self: center;*/
        @include media-breakpoint-only(xs) {
            .from {
                display: inline-block;
            }
        }

        .renewsat {
            font-size: $font-size-sm;
            color: $text-muted;
        }
    }
    // TLD short description (if shown)
    .tile-footer {
        margin-top: 0.5rem;
        text-align: center;
        padding: 0 3rem;
        font-size: $font-size-sm;
        flex: 1 0 0px;
    }

    @include media-breakpoint-between(sm, md) {
        margin-top: 4rem;

        .tile {
            flex-basis: 50%;
            margin-bottom: 4rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .tile {
            margin-bottom: 1rem;
        }
    }
}