﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/card";


//
// TILES are exactly the same as cards except they don't default to any style.
// That means they have no background, border or other style attached.  They are used for laying
// out content which must be then styled separately. 
//
//  Don't make changes here unless bootstrap has changed.  Please overrides and make changes in own section lower in this file
//

.tile {
    // this is EXACTLY the same as .card but with formatting, etc, removed
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
}



/*.tile-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    padding: $card-spacer-x;
}

.tile-header {
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
}

.tile-footer {
    padding: $card-spacer-y $card-spacer-x;
}*/



//
// CARD DECK
//  Exact copy of Bootstraps _card.scss but without background, border, etc.
//  Don't make changes here unless bootstrap has changed.  Please overrides and make changes in own section lower in this file
//

.card-deck {
    > .tile {
        margin-bottom: $card-deck-margin;
    }

    @include media-breakpoint-up(sm) {
        > .tile {
            display: flex;
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
            flex: 1 0 0%;
            flex-direction: column;
            flex-direction: row;
            margin-right: $card-deck-margin;
            margin-bottom: 0; // Override the default
            margin-left: $card-deck-margin;
        }
    }
}


//
// CARD GROUP
//  Exact copy of Bootstraps _card.scss but without background, border, etc.
//  Don't make changes here unless bootstrap has changed.  Please overrides and make changes in own section lower in this file
//


.card-group {
    > .tile {
        margin-bottom: $card-group-margin;
    }

    @include media-breakpoint-up(sm) {
        // The child selector allows nested `.card` within `.card-group`
        // to display properly.
        > .tile {
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
            flex: 1 0 0%;
            margin-bottom: 0;

            + .tile {
                margin-left: 0;
                border-left: 0;
            }
        }
    }
}

//
// CARD COLUMNS
//  We don't support tiles within card columns
//







//
// *************************************************************************************************************************************
//
//  Overrides, Changes, and Custom stuff
//
//   Make changes to default handling below here.  The above should be exact copies of _cards.scss but with .tile rather than .card
//   selectors (and with all background, borders, etc, removed).
//
// *************************************************************************************************************************************
//



//  CARD LISTS
//  This is UL>LI's that render as cards but without all the markup required for nesting cards withing <li>'s
//  **NB: This is our own custom thing, mainly using in things like the domain checker

ul.card-list {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        background: $card-bg;
        padding: $card-spacer-x ($card-spacer-x * 1.5);
        border: solid 1px $card-border-color;
        margin-bottom: 1rem;

        @if $enable-shadows {
            box-shadow: rgba(0,0,0, 0.1) -1px 2px;
        }
    }
}


@include media-breakpoint-up(sm) {
    .card-deck {
        &.col2, &.col3 {
            .card, .tile {
                flex-basis: calc(32% - #{$card-deck-margin} );
                margin-bottom: $card-deck-margin;
            }
        }
    }
}


@include media-breakpoint-up(md) {
    // .card-deck usually has no limits to how many cards it holds,
    // they just get smaller (to be kept in one row)
    // These helpers limit to either 2 or 3 column and let the extras
    // wrap around
    .card-deck {

        &.col2, &.col3 {
            flex-wrap: wrap;
            flex: 1 1 0;
        }

        &.col2 {
            .card, .tile {
                min-width: 40%;
                margin-bottom: $card-deck-margin;
            }
        }


        &.col3 {
            .card, .tile {
                min-width: 30%;
                margin-bottom: $card-deck-margin;
            }
        }
    }
}

/*.tile {
    & > .tile-body {
    }
}*/

.white-tiles {
    .tile {
        display: flex;
        margin-bottom: 1rem;
        text-align: left;
        flex-direction: row;

        & > a {
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
            display: flex;
            /*height: 100%;*/
            width: 100%;
            color: $body-color;

            & > i {
                flex: 0 0 80px;
                padding: 3px 0;
                color: theme-color('info');
                font-size: 40px;
                line-height: 1.7;
                text-align: center;
                position: relative;
            }



            & > .tile-body {
                flex: 1 1 0%;
                padding: $card-spacer-x;
                min-width: 80px;
            }

            .title {
                color: $headings-color;
                font-family: $headings-font-family;
                font-weight: 400;
                font-size: $h2-font-size;
            }

            & > i + .tile-body {
                padding-left: 0;
            }
        }
    }

    &.tiles-sm {
        .tile {
            & > a {
                & > i {
                    flex: 0 0 60px;
                    font-size: 28px;
                }
            }
        }
    }
}
/*// tiles are used for laying out other content so lets make them flex-able
.tile-header, .tile-body, .tile-footer {
display: flex;
}




@include media-breakpoint-up(sm) {
// Seperated? Isn't this just a card-deck?
.card-group.seperated {
    .tile:not(:first-child) {
        //margin-left: $card-deck-margin;
        position: relative;

        &:after {
            content: " ";
            width: 1px;
            border-left: solid 2px #fff;
            position: absolute;
            height: 100%;
            //height: 80%;
            //margin: 5% 0;
        }
    }
}
// if we're in a white container we need to change the spacer/separator bar colour
.white-ct-wrap .card-group.seperated {
    .tile:not(:first-child) {
        &:after {
            border-color: $body-bg;
        }
    }
}
}

*/
//
// Changes to the default Cards
//
.card {
    overflow: hidden;
}

.card-header {
    background-color: $gray-100;

    i {
        color: theme-color('info');
    }
}

.card-footer {
    border-top: none;
}
/*
.tiled {

.stat {
    font-size: $font-size-lg;
    font-weight: bold;
    padding-top: 15px;
}

.name {
    padding-bottom: 15px;
}

.stat, .name {
    text-align: center;
}



p {
    color: $body-color;
}
} */


