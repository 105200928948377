@include exports("forms/layout") {

    // Widgets
    .k-autocomplete,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker {
        width: 12.4em;
        line-height: $form-line-height;
        text-align: left;
        white-space: nowrap;
        display: inline-flex;
        vertical-align: middle;
        position: relative;

        &[dir='rtl'],
        .k-rtl & {
            text-align: right;

            .k-select {
                @include border-left-radius-only();
            }

            .k-clear-value {
                left: $padding-x;
                right: auto;
            }
        }

        ::-ms-clear {
            display: none;
        }
    }
    .k-colorpicker,
    .k-combobox,
    .k-dateinput,
    .k-datepicker,
    .k-datetimepicker,
    .k-dropdown,
    .k-multiselect,
    .k-numerictextbox,
    .k-maskedtextbox,
    .k-timepicker {
        border-width: 0;
        background-color: transparent;
    }

    .k-nodata {
        min-height: 138px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: lighter;
        text-align: center;
        white-space: normal;
    }


    // Searchbar
    .k-searchbar {
        flex: 1;
        display: flex;
        flex-direction: row;
    }


    // Wrappers
    .k-dateinput-wrap,
    .k-dropdown-wrap,
    .k-picker-wrap,
    .k-multiselect-wrap,
    .k-numeric-wrap {
        @include border-radius();
        padding: 0;
        width: 100%;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        position: relative;
        transition: all .1s ease; // sass-lint:disable-block no-transition-all
        // TODO: improve wrapper overflow
        //overflow: hidden;
        cursor: default;
        outline: 0;

        .k-input {
            padding: $input-padding-y $input-padding-x;
            height: button-inner-size();
            box-sizing: border-box;
            border: 0;
            outline: 0;
            color: inherit;
            background: none;
            font: inherit;
            flex: 0 1 auto;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    // Other common
    .k-dateinput-wrap,
    .k-dropdown-wrap,
    .k-picker-wrap,
    .k-numeric-wrap {
        display: flex;
        flex-direction: row;

        .k-input {
            width: 100%;
        }

        .k-select {
            @include border-right-radius-only();
            padding: $button-padding-y;
            border-width: 0 0 0 1px;
            box-sizing: border-box;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;

            .k-icon {}
        }
    }

    .k-autocomplete,
    .k-dropdown-wrap,
    .k-multiselect {
        .k-clear-value,
        .k-i-loading {
            position: absolute;
            top: 50%;
            margin-top: -.5em;
        }

        .k-clear-value {
            right: $padding-x;
            display: none;
        }

        .k-i-loading {
            right: $padding-x-lg / 2;
        }

        &.k-state-focused,
        &.k-state-hover,
        &:hover {
            .k-clear-value {
                display: inline-block;
                outline: 0;
                opacity: .5;

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }

    .k-dateinput,
    .k-numerictextbox {

        .k-select {
            padding: 0;
            flex-direction: column;
            align-items: stretch;
            width: button-inner-size();
        }
        .k-link {
            display: block;
            height: button-inner-size(.5);
            overflow: hidden;
            position: relative;
            width: 100%;

            .k-icon {
                position: absolute;
                right: 50%;
                transform: translateX(50%);
            }
        }

        .k-link-increase {
            .k-icon {
                bottom: 0;
            }
        }

        .k-link-decrease {
            .k-icon {
                top: 0;
            }
        }
    }

    .k-virtual-content {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: relative;

        > .k-virtual-list > .k-virtual-item {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
        }
    }

}




@include exports( "stylehelpers/layout" ) {

    // Style helpers
    .k-textbox.k-space-left {
        padding-left: 1.9em;
    }

    .k-textbox.k-space-right {
        padding-right: 1.9em;
    }

    .k-textbox .k-icon {
        top: 50%;
        margin: -8px 0 0;
        position: absolute;
    }

    .k-space-left .k-icon {
        left: 3px;
    }

    .k-space-right .k-icon {
        right: 3px;
    }

    .k-widget.ng-invalid.ng-touched,
    .k-widget.ng-invalid.ng-dirty {
        .k-dateinput-wrap,
        .k-dropdown-wrap,
        .k-numeric-wrap,
        .k-picker-wrap {
            color: $error;
            border-color: $invalid-border;

            .k-select {
                color: inherit;
            }
        }
    }

}

@include exports("forms/integration-with-bootstrap-forms") {
    $twbs-font-size-base: 1rem !default;
    $twbs-input-padding-x: .75rem !default;
    $twbs-input-padding-y: .5rem !default;
    $twbs-input-line-height: 1.25 !default;

    $bootstrap-integration-input-height: ($twbs-input-padding-y * 2) + ($twbs-font-size-base * $twbs-input-line-height) !default;

    $twbs-font-size-base-sm: .875rem !default;
    $twbs-input-padding-x-sm: .5rem !default;
    $twbs-input-padding-y-sm: .25rem !default;
    $twbs-input-line-height-sm: 1.5 !default;

    $bootstrap-integration-input-height-sm: ($twbs-input-padding-y-sm * 2) + ($twbs-font-size-base-sm * $twbs-input-line-height-sm) !default;

    $twbs-font-size-base-lg: 1.25rem !default;
    $twbs-input-padding-x-lg: 1rem !default;
    $twbs-input-padding-y-lg: .5rem !default;
    $twbs-input-line-height-lg: 1.5 !default;

    $bootstrap-integration-input-height-lg: ($twbs-input-padding-y-lg * 2) + ($twbs-font-size-base-lg * $twbs-input-line-height-lg) !default;

    $numerictextbox-icon-offset-sm: 2px !default;

    .k-widget.form-control {
        padding: 0;
    }

    .k-widget.form-control,
    .k-maskedtextbox.form-control .k-textbox,
    .k-textbox.form-control {
        width: 100%;
    }

    .k-autocomplete.form-control .k-input {
        box-sizing: border-box;
    }

    .input-group {
        .k-dateinput-wrap,
        .k-dropdown-wrap,
        .k-multiselect-wrap,
        .k-numeric-wrap,
        .k-picker-wrap,
        .k-maskedtextbox .k-textbox {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .k-select {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .k-widget.form-control:not(:first-child):not(:last-child) {
            .k-dateinput-wrap,
            .k-dropdown-wrap,
            .k-multiselect-wrap,
            .k-numeric-wrap,
            .k-picker-wrap,
            > .k-textbox {
                border-radius: 0;
            }
        }
    }

    .input-group-addon + .k-widget.form-control {
        .k-dateinput-wrap,
        .k-dropdown-wrap,
        .k-multiselect-wrap,
        .k-numeric-wrap,
        .k-picker-wrap,
        > .k-textbox {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    // Bootstrap form-control, form-control-sm, form-control-lg
    .k-widget {
        &.form-control {
            .k-input {
                padding: $twbs-input-padding-y $twbs-input-padding-x;
                height: $bootstrap-integration-input-height;
            }

            &-sm {
                .k-input {
                    padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                    height: $bootstrap-integration-input-height-sm;
                }

                .k-dateinput-wrap,
                .k-dropdown-wrap,
                .k-picker-wrap {
                    .k-select {
                        padding: $twbs-input-padding-y-sm;
                    }
                }

                .k-select {
                    width: $bootstrap-integration-input-height-sm;
                }
            }

            &-lg {
                .k-input {
                    padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                    height: $bootstrap-integration-input-height-lg;
                }

                .k-dateinput-wrap,
                .k-dropdown-wrap,
                .k-picker-wrap {
                    .k-select {
                        padding: $twbs-input-padding-y-lg;
                    }
                }

                .k-select {
                    width: $bootstrap-integration-input-height-lg;
                }
            }
        }
    }

    .k-maskedtextbox {
        &.form-control {
            .k-textbox {
                height: add-two($bootstrap-integration-input-height, 2 * $input-border-width);
                padding: $twbs-input-padding-y $twbs-input-padding-x;
            }

            &-sm {
                .k-textbox {
                    height: add-two($bootstrap-integration-input-height-sm, 2 * $input-border-width);
                    padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                    line-height: $twbs-input-line-height-sm;
                }
            }

            &-lg {
                .k-textbox {
                    height: add-two($bootstrap-integration-input-height-lg, 2 * $input-border-width);
                    padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                    line-height: $twbs-input-line-height-lg;
                }
            }
        }
    }

    .k-textbox {
        &.form-control {
            height: add-two($bootstrap-integration-input-height, 2 * $input-border-width);
            padding: $twbs-input-padding-y $twbs-input-padding-x;

            &-sm {
                height: add-two($bootstrap-integration-input-height-sm, 2 * $input-border-width);
                padding: $twbs-input-padding-y-sm $twbs-input-padding-x-sm;
                line-height: $twbs-input-line-height-sm;
            }

            &-lg {
                height: add-two($bootstrap-integration-input-height-lg, 2 * $input-border-width);
                padding: $twbs-input-padding-y-lg $twbs-input-padding-x-lg;
                line-height: $twbs-input-line-height-lg;
            }
        }
    }

    .k-dateinput,
    .k-numerictextbox {
        &.form-control {
            &-sm {
                .k-link {
                    height: $bootstrap-integration-input-height-sm / 2;
                }
            }

            &-lg {
                .k-link {
                    height: $bootstrap-integration-input-height-lg / 2;
                }
            }
        }
    }

    .k-numerictextbox,
    .k-dateinput {
        &.form-control {
            &-sm {
                .k-link-increase > .k-icon {
                    bottom: -$numerictextbox-icon-offset-sm;
                }

                .k-link-decrease > .k-icon {
                    top: -$numerictextbox-icon-offset-sm;
                }
            }
        }
    }

    .k-dateinput {
        &.form-control {
            .k-dateinput-wrap {
                .k-select {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
