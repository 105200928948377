﻿@import "variables";


.scrollup {
    width: 40px;
    height: 40px;
    /*-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);*/
    /*filter: alpha(opacity=80);
    opacity: 0.8;*/
    position: fixed;
    bottom: 100px;
    right: 25px;
    display: none;
    text-align: center;
    color: #fff;
    font-size: 40px;
    background: theme-color('info');
    z-index: 9999;
    border-radius: 10%;
    line-height: 0;

    i {
        line-height: 38px;
        color: $body-bg;
    }

    &:hover, &:focus {
        background: theme-color('success');
        -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        filter: alpha(opacity=100);
        opacity: 1;

        i {
            text-decoration: none;
            color: #fff;
            outline: none;
        }
    }
}
