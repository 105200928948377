@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/navbar";


/*.navbar > .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}*/


.navbar {
    padding: 0;

    & > .container {
        padding-left: 15px;

        @include media-breakpoint-up(lg) {
            padding-left: 0px;
        }
    }
}


.navbar-collapse {
    flex-grow: 0;
}

.navbar-nav {


    .nav-item {
        margin: 0;
        padding: 0;
    }

    .nav-link, button {
        margin: 0;
    }

    .dropdown-menu {
        margin-top: 1px;
        border: 0;

        a {
            margin: 0.5rem 1rem;
            padding: 1rem;
            white-space: nowrap;
            display: block;
        }
    }
}