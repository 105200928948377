@import "variables";


footer.footer {

    a, a:visited {
        color: $gray-300;
    }

    a:focus, a:hover {
        color: color-contrast(theme-color('primary'), theme-color('primary'));
        cursor: pointer;
    }

    section {
        margin-top: 0;
    }
}

// Footer Top =====================================================================
#fttop {
    color: $gray-300;
    background: theme-color('primary');
    padding: 30px 0;
    border-bottom: solid 1px $gray-500;
    border-top: solid 1px $gray-500;

    .container {
        display: flex;
        flex-flow: row wrap;
        /*@include media-breakpoint-up(sm) {
            flex-flow: row wrap;
        }*/
        & > div {
            flex: 1 1 100%;
            margin-bottom: 2rem;

            @include media-breakpoint-up(sm) {
                flex-basis: 50%;
            }

            @include media-breakpoint-up(md) {
                flex-basis: 25%;
            }
        }
    }

    h3 {
        color: $gray-300;
        margin-bottom: 2rem;
    }

    .fa {
        margin-right: 5px;
    }

    ul {
        li {
            padding-bottom: 5px;
        }

        li.indent {
            margin-left: 20px;

            .fa {
                margin-left: -20px;
            }
        }
    }
}




// Footer Bottom =====================================================================
#ftbtm {
    background: theme-color('dark');
    color: theme-color('light');
    padding: 10px 0 20px;
    line-height: normal;

    .list-inline {

        li {
            font-size: $font-size-sm;
            margin-right: 20px;
            line-height: 3rem;
        }
        
        @include media-breakpoint-only(xs) {
            text-align: center;
        }
    }
}

.copyright {
    font-family: $headings-font-family;
    font-size: $font-size-sm;
}
