// Fill and outline
@mixin fill( $color: null, $bg: null, $border: null, $gradient: null ) {
    border-color: $border;
    color: $color;
    background-color: $bg;
    @include linear-gradient( $gradient );
}
@mixin outline( $color: null ) {
    border-color: $color;
    color: $color;
    background-color: transparent;
    background-image: none;
}
