﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/breadcrumb";

/*.breadcrumb {
    font-size: $font-size-sm;
    background-color: transparent;
    //color: color-contrast($gray-600, $body-bg);
    color: $gray-700;
    margin: 0 0 1rem 0;
    padding: 0;

    li {
        &:after {
            content: ">";
            color: $gray-600;
            padding-left: 5px;
        }

        &:last-child {
            &:after {
                content: "";
            }
        }
    }


    a {
        color: inherit;
    }
}*/

.breadcrumb {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 2rem;
    list-style: none;
    background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item + .breadcrumb-item:before {
    display: inline-block;
    padding-right: .5rem;
    color: #ced4da;
    content: "/"
}

@supports (hover:hover) {
    .breadcrumb-item + .breadcrumb-item:hover:before {
        text-decoration: underline;
        text-decoration: none
    }
}
