﻿@import "variables";


.split_container {
    display: flex;
    flex-grow: 1;

    .bgmgl {
        flex: 1 1 auto;
    }

    .container {
        .content {
            padding-bottom: 2rem;
        }
    }

    .bgmgr {
        flex: 1 1 auto;
    }
}