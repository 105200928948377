﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/tables";

.xs-label, .sm-label {
    display: none;
}

@include media-breakpoint-down(xs) {
    .xs-label {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .xs-label, .sm-label {
        padding: 0 !important;
        margin: 0 0 0.3rem 0.1rem;
        font-size: $font-size-sm;
        color: theme-color('info');
    }

    .sm-label {
        display: block;
    }
}

.table {
    th {
        padding-top: 0;
    }

    th, td {
        border-top: 0;
    }
}

.k-widget.k-grid {
    border: 0;
}

.k-grid, .grid {
    border: none;

    a, a:visited {
        color: $link-color;
    }

    a:hover {
        color: $link-hover-color;
    }

    th {
        outline: 0;
        font-weight: inherit;
        text-align: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    tbody {
        @include media-breakpoint-up(md) {
            border: solid 1px $card-border-color;
            box-shadow: rgba(0,0,0, 0.1) -1px 2px;
            background-color: $card-bg;
        }

        td {
            border: 0;

            &.name a {
                font-weight: bold;
            }
        }


        tr {
            border: none;

            @include media-breakpoint-up(md) {
                padding: $card-spacer-x;
            }

            @supports (hover:hover) {
                &.k-detail-row:hover {
                    background-color: $card-bg;
                    color: $body-color;
                }
            }
        }
    }
}

.k-widget.k-grid-pager {
    display: flex;
    margin-top: 1rem;
    border: solid 1px $card-border-color;
    box-shadow: rgba(0,0,0, 0.1) -1px 2px;
    background-color: $card-bg;
    padding: $card-spacer-x * 0.75;
}

.k-detail-row {
    padding-top: 0;

    td .k-detail-cell {
        padding-top: 0;
    }

    .tools {
        margin-top: 0;
        margin-bottom: 2rem
    }
}


.k-grid-header {
    th {
        border: 0;
    }
}

@include media-breakpoint-down(sm) {
    table, tbody {
        width: 100%;
        display: block;
    }

    .k-grid-header, thead {
        display: none;
    }

    tbody {
        border: 0;
        background-color: $body-bg;
    }

    tr {
        position: relative;
    }

    tr:not(.k-detail-row) { //.k-master-row {
        border: solid 1px $card-border-color;
        box-shadow: rgba(0,0,0, 0.1) -1px 2px;
        background-color: $card-bg;
        display: flex;
        flex-wrap: wrap;

        &:not(first-child) {
            margin-top: 1rem;
        }

        td {
            flex: 1 1 30%;
            //max-width: 30%;
            padding: 1rem 0 1rem 1rem;
            border: 0;

            &.sm-10 {
                //width: 10%;
                flex-basis: 10%;
            }

            &.sm-50 {
                flex-basis: 50%;
            }

            &.sm-100 {
                //width: 100%;
                flex-basis: 100%;
            }

            &.sm-name {
                font-weight: bold;
            }
        }


        .k-hierarchy-cell {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50px;

            .k-i-expand:before {
                content: "\e11e";
            }
        }
    }

    .k-detail-row, .k-detail-row.k-alt {
        width: 100%;
        display: block;
        background: transparent;
    }

    .k-detail-cell {
        display: block;
    }

    .k-pager-input.k-label {
        display: none;
    }

    .sm-hide {
        display: none;
    }
}

