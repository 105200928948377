﻿@import "variables";


.matrix {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    > div {
        border-right: solid 1px $gray-400;
    }

    .blank {
        border-right: none;
    }

    .name, .desc, .f-value {
        text-align: center;
    }

    .card-title, h4 {
        text-overflow: ellipsis;
        /*white-space: nowrap;*/
        overflow: hidden;
    }

    .desc {
        padding: 0 1rem;
    }

    .pricing, .buynow {
        text-align: center;
        padding: 1rem 0 1rem 0;
    }

    .buynow {
        .btn {
            max-width: 95%;
        }
    }

    .learn {
        margin-bottom: 1rem;
    }


    .f-name, .f-value {
        padding: 0.5rem 0 0.5rem 0.5rem;
    }

    .f-value {
        padding-left: 0;

        .fa-minus {
            color: $text-muted;
        }

        .fa-check {
            color: theme-color('info');
        }
    }

    .altrow {
        background-color: $gray-200;
    }


    @include media-breakpoint-only(xs) {
        .blank {
            display: none;
        }

        .f-name {
            flex-basis: 100% !important;
            max-width: 100% !important;
            text-align: center;
            border-right: none;
        }

        .pricing {
            span {
                display: block;
            }
        }

        .buynow {
            .btn {
                width: 100%;
                padding: 1rem 0;
                text-align: center;
            }
        }
    }
}

@for $i from 1 through 4 {
    .matrix.items#{$i} {
        > div {
            flex: 0 0 percentage(1 / ($i+1));
            max-width: percentage(1 / ($i+1));

            @include media-breakpoint-only(xs) {
                flex-basis: percentage(1 / $i);
                max-width: percentage(1 / $i);
            }

            &:nth-child(#{$i+1}n+0) {
                border-right: none;
            }
        }
    }
}
