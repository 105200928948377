@import "variables";


$navbar-darker: theme-color-level('dark', 3);


@keyframes ring
  {
    0% { transform: rotate(0deg) }
    5% { transform: rotate(45deg) }
    15% { transform: rotate(-40deg) }
    25% { transform: rotate(20deg) }
    35% { transform: rotate(-15deg) }
    45% { transform: rotate(10deg) }
    55% { transform: rotate(-5deg) }
    60% { transform: rotate(0deg) }
    100% { transform: rotate(0deg) }
  }

.has-count.active {
    color: #fff !important;

    .count-ct {
        background-color: theme-color('warning');
        padding: 0.3rem 1rem 0.3rem 1rem;
        border-radius: 5%;
    }

    .count {
        padding: 0 .5rem;
    }

    .fa-bell {
        transform-origin: 5px 0;
        animation:  ring 2s 0s 10;
    }
}


.toggler.collapsed, .dropdown.show .dropdown-toggle {
    &:after {
        transform: rotate(180deg);
    }
}


// Topbar =====================================================================
.linksbar {
    background-color: $dark;
    color: color-contrast($gray-900, $dark);
    font-size: $font-size-sm;
    position: relative;
    z-index: 1100;
    height: 40px;
    flex: 0 0 auto;

    & > .container {
        padding-right: 0 !important;
    }

    .nav-link, .toggler {
        color: color-contrast($gray-900, $dark);
        font-size: $font-size-sm;
        padding: 1.1rem 1.5rem !important;

        @media (hover:hover) {
            &:hover {
                color: color-yiq($navbar-darker);
                background-color: $navbar-darker !important;
            }
        }
    }

    .toggler {
        display: inline-block;
        @include caret(up);
    }


    .navbar-toggler {
        background-color: $navbar-darker;
        text-align: right;

        &.collapsed {
            background-color: $dark;
        }
    }

    .auth {
        margin-left: 2rem;
    }


    .dropdown-menu {
        background: $navbar-darker;
        font-size: $font-size-sm;

        a {
            color: color-contrast($gray-900, $dark);

            @media (hover:hover) {
                &:hover {
                    color: color-yiq($navbar-darker);
                    background-color: theme-color('dark') !important;
                }
            }
        }
    }

    .dropdown-toggle[aria-expanded="true"] {
        color: color-yiq($navbar-darker);
        background: $navbar-darker;
    }

    .navbar-brand {
        font-style: italic;
        font-size: $font-size-sm;
        flex-grow: 1;
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;

        .navbar-nav {
            background-color: $navbar-darker;
            border-bottom: solid 5px theme-color('success');
            padding: 1.2rem 0;

            li.noitems {
                width: 100%;
                color: color-contrast($gray-900, $dark);
                text-align: center;
                margin: 2rem;
            }
        }
    }

    .alert-item {
        width: 100% !important;

        a {
            display: flex;
            align-items: center;

            .fas {
                flex: 0 0 2.5rem;
                font-size: 2.5rem;
            }

            .message {
                flex: 1 1 100%;
                margin-left: 1rem;
            }
        }
    }
} // .linksbar




@media only screen and (max-width: 374px) {
    .linksbar {
        .navbar-brand {
            display: none;
        }
    }
}

/*
** Small and Extra Small screens
**
*/
@include media-breakpoint-down(sm) {
    .linksbar {
        & > .container {
            justify-content: flex-end;
        }

        .navbar-nav {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -0.2rem;
            background-color: $navbar-darker;
            border-bottom: solid 5px theme-color('success');
            padding: 1.2rem 0;


            li {
                width: 44%;
                margin: 0 1rem;

                a {
                    padding: 1.0rem 1rem !important;

                    &:hover {
                        background-color: theme-color('dark') !important;
                    }
                }
            }
        }

        .dropdown {
            position: inherit;
        }

        .navbar-collapse, .dropdown-menu {
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
            z-index: 9999;
            left: 0;
            top: 4rem;
        }
    }
    // .linksbar
}


@include media-breakpoint-up(md) {
    .linksbar {
        .dropdown-menu {
            .navbar-nav {
                flex-direction: column;
            }
        }

        .auth .navbar-nav {
            width: 180px;
        }

        #noticedd {
            .dropdown-menu {
                margin-left: -150px;
            }

            .navbar-nav {
                /*width: 250px;*/

                li {
                    width: auto;
                }
            }
        }
    }
}