@import "variables"; 


/*.top-margin {
    margin-top: 40px;
}

.top-margin-sm {
    margin-top: 20px;   
}

.bottom-margin {
    margin-bottom: 40px;
}

.bottom-margin-sm {
    margin-bottom: 20px;
}

.top-bottom-margin {
    margin: 40px;
}

.top-pad {
    margin-top: 25px;
}

.bottom-pad {
    margin-bottom: 60px;
}*/

.bottom-pad-sm {
    margin-bottom: 30px;
}

.no-bottom-pad {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}


@include media-breakpoint-only(xs) {
    .bottom-pad-sm {
        margin-bottom: 80px;
    }     
}

