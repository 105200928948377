﻿@import "variables";



// taken from the boptstrap grid-framework
@at-root {
    @-ms-viewport {
        width: device-width;
    }
    // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}


body {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    > div#wrapper {
        flex-grow: 1;
    }
}

table {
    font-size: $font-size-base;
}


.container {
    @include media-breakpoint-down(md) {
        max-width: 100% !important;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


main {
    flex: 1 1 100px;
    padding: 0 15px;
    max-width: 100%;
}

.sidebar {
    section {
        margin-bottom: 4rem;
    }

    section:first-child {
        margin-top: 0;
    }

    .plain-list {
        margin-top: 0.5rem;
    }
}

main + .sidebar {
    padding: 0 ( $grid-gutter-width / 2);
    width: 100%;
    margin-top: 3rem;
}

@include media-breakpoint-up(md) {
    main + .sidebar {
        flex: 0 0 250px;
        max-width: 250px;
        width: auto;
        margin-top: 0;
    }
}




img {
    max-width: 100%; // make imgs more responsive in terms of appearance
}
