@import "variables";




.pageheader {
    margin-bottom: 1rem;
    padding-bottom: 0;

    h1 {
        /*color: $gray-600;*/
        margin: 2rem 0 8px -3px;
        /*margin: 0 0 2rem 0;*/

    }

    .subtitle {

    }
}

/*.breadcrumb {
    margin: 1rem 0 0 0 ;
}*/