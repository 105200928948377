﻿@import "_auth_variables";




.secure-pg {

    body {
        height: 100%;
    }

    #wrapper {
        display: flex;
        margin-bottom: 0 !important;
        min-height: 500px;
    }

    .outer {
        display: flex;
        width: 100%;

        footer {
            background-color: $gray-400;
            padding: 10px 20px;
            color: color-contrast(theme-color('dark'), $gray-400);

            a {
                color: $body-color;
            }
        }
    }


    .full-panel {
        background-color: $body-bg;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }



    .left-panel {
        display: flex;
        flex-direction: column;
        flex-grow: 1;


        .logo {
            /*height: 150px;*/
            width: 200px; // 160px;
            margin: 0 auto 20px auto;
        }

        p, h3 {
            text-align: center;
        }
    }

    .inner { // Left panel inner wrapper
        flex-grow: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 30px;

        > div {
            display: flex;
            flex-direction: column;

            .form-group:last-child {
                margin-top: 20px;
            }
        }
    }


    .right-panel {
        display: none;
        background-color: #ff6a00;
        flex: 0 0 350px;
        width: 350px;
        height: 500px;
    }

    // temporarily disable the cancel button on safari
    // while we fix the issue with button names not passing
    // back to the server when using jQueryValidation
    &.k-safari {
        .left-panel {
            button[formnovalidate] {
                display: none;
            }
        }

    }
}

@media only screen and (min-width : 882px) {
    .secure-pg {
        /*.linksbar, #ftbtm {
            background-color: rgba($dark, 0.5);
        }*/
        .bg {
            // URL for getting a data feed from Bing Image of the day
            // https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=20&mkt=en-gb
            //
            background-image: url(https://cdn.gybol.net/g/media/bg/winnatspass.jpg);
            flex: 1 1 auto;
            /* make this a container for the inner/panels*/
            /*display: flex;*/
            justify-content: center;
            align-items: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .outer {
            min-height: 500px;
            width: 882px;
        }

        .inner {
            background-color: $body-bg;
            padding: 0 40px;
            margin: 0;
        }

        .left-panel {
            flex-grow: 0;
            width: 530px;
        }

        .right-panel {
            display: block;
        }
    }
}

