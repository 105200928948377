@include exports("dropdownlist/layout") {

    .k-dropdown {

        .k-select {
            border-width: 0;
        }

    }

    .k-dropdown-wrap {
        outline: 0;

        > .k-input {
            flex: 1;
        }
    }

    .k-dropdown-operator {
        width: auto;

        .k-input {
            display: none;
        }

        .k-select {
            width: button-inner-size();
            height: button-inner-size();
        }
    }

}
