﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/alert";



@each $name in $basic-color-states {
    .alert-#{$name} {
        border-color: theme-color($name);
        color: theme-color($name);

        & > .fa, & > .fas {
            color: theme-color($name);
        }

        .close {
            color: theme-color($name);
        }
    }
}



.alert {
    position: static;
    display: flex;
    background-color: #fff;
    padding-left: 0;
    flex-direction: row;
    font-weight: bold;

    & > i, & > .spinner {
        flex: 0 0 30px;
        margin-right: 1rem;
        padding: 0.8rem 0;
    }

    & > i {
        font-size: 40px;
        text-align: center;
        margin-left: 1rem;
    }

    .close {
        position: absolute;
        border: none;
        background: none;
        top: 0px;
        right: 5px;
        font-size: 22px;
        font-weight: bold;
    }

    .body {
        flex: 1 1 0;
        align-self: center;
    }

    .tools {
        @extend .clearfix;
        text-align: right;
        margin-bottom: 0.5rem;
    }

    .spinner {
        display: none;
    }


    &.state-working 
    {
        .normal {
            display: none;
        } 

        .inline-spinner {
            display: inline-block !important;
        }

        .page-spinner {
            display: block !important;
        }
    }

}



.alert-worker {
    .working, .done, .fail {
        display: none;
    }

    .normal {
        display: block;
    }

    &.state-working {
        border-color: theme-color("info");

        .normal {
            display: none;
        }

        .working {
            align-self: center;
            display: block;
        }
    }

    &.state-done {
        border-color: theme-color("success");
        color: theme-color("primary");

        .fas.done {
            color: theme-color("success");
        }

        .normal {
            display: none;
        }

        .done {
            display: block;
        }
    }

    &.state-fail {
        border-color: theme-color("danger");
        color: theme-color("danger");

        .fas.fail {
            color: theme-color("danger");
        }

        .normal {
            display: none;
        }

        .fail {
            display: block;
        }
    }
}
