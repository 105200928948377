﻿@import "variables";



#st_Widget.st_WidgetBR.Image {
    bottom: 30px !important;
    right: 25px !important;
    text-align: right;
}

#st_Widget {
    .st_ImageDiv {

        &.Bottom {
            margin-top: 10px !important;
        }
    }
}

#gyLveCht {
    #st_Widget .st_WidgetWrapper.Right {
        right: 0px !important;
    }

    #st_Widget .st_ImageDiv .st_LogoImage {
        transform: none !important;
    }
}

#st_LogoImage {
    -webkit-filter: none !important;
    filter: none !important;
}

#st_txtBoxQuestion {
    height: 100px !important;
}


.st_OneTimeMessagePlaceHolder {
    display: none;
}

.st_CustomFields {
}




#st_CopyRight {
    display: none;
}
