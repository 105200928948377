﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/type";


h1 {
    margin: 0 0 2rem;
}

h2 {
    font-weight: bold;
    margin: 4rem 0 1rem 0;
}

h3 {
    margin: 2rem 0 0.5rem 0;
}

h4 {
    line-height: 1.3;
}


.sidebar {
    h5 {
        color: $gray-800;
        margin-bottom: 0.5rem;
        border-bottom: solid 1px $gray-500;
        padding-bottom: 0.7rem;
    }
}

@include bg-variant('.bg-gray', $light);



textarea {
    resize: none !important;
}

.decorated {
    display: flex;

    &>i.fa, &>span.fa-stack {
        flex:  0 0 70px;
        text-align: center;
    }
}



.list-inline {
    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: $list-inline-padding;
        }
    }
}


ul.nav-stacked {

    li {
        width: 100%;
        margin: 0.5rem;
    }
}

ul.plain-list {
    padding: 0;
    list-style: none;

    .list-item {
        margin-bottom: 1rem;
        display: block;
    }
}

ul.icon-list {
    .list-item {
        margin-left: 2.3rem;

        i {
            margin-left: -2.3rem;
            padding-right: 0.6rem;
        }
    }
}

ol {
    counter-reset: section;
    list-style-type: none;

    li {
        counter-increment: section;
        margin-bottom: 1rem;

        &:before {
            content: counter(section) ".";
            margin-left: -4rem;
            width: 4rem;
            display: inline-block;
        }
    }
}




ol.tiered {
    list-style-type: none;
    margin-bottom: 5rem;
    padding-left: 0;


    h2 {
        &:before {
            content: counter(section) ".";
            margin-right: 1rem;
        }
    }

    h3 {
        margin-top: 1rem;

        &:before {
            content: counter(section) "." counter(paragraph);
            margin: -1rem 0 0.5rem -2rem;
            width: 4rem;
            display: inline-block;
        }
    }

    ol {
        padding-left: 5rem;
    }

    li {
        /*list-style-type: none;*/
        &:before {
            margin-left: -5rem;
            width: 5rem;
            display: inline-block;
        }

        &.titled {
            margin-left: -3rem;

            h3 {
                margin-top: -1rem;
            }
        }
    }

    & > li {

        &:before {
            display: none;
        }

        & > ol {
            counter-reset: paragraph;

            & > li {
                counter-increment: paragraph;

                &:before {
                    content: counter(section) "." counter(paragraph);
                }

                &.titled {

                    &:before {
                        content: " ";
                        margin: 0;
                    }
                }

                & > ol {
                    counter-reset: subpara;
                    margin-top: 1rem;

                    & > li {
                        counter-increment: subpara;

                        &:before {
                            content: counter(section) "." counter(paragraph) "." counter(subpara);
                        }
                    }
                }
            }
        }
    }
}


ol.alpha {
    counter-reset: alpha;

    li {
        counter-increment: alpha !important;

        &:before {
            content: counter(alpha, lower-alpha) "." !important;
        }

        &.titled {
            &:before {
                content: " " !important;
                margin: 0;
            }
        }

        h3 {
            margin: 0;

            &:before {
                content: counter(alpha, lower-alpha) "." !important;
                width: 2rem;
                margin-left: -2rem;
                display: inline-block;
            }
        }
    }
}

ol.roman {
    counter-reset: roman;

    li {
        counter-increment: roman !important;

        &:before {
            content: counter(roman, lower-roman) "." !important;
        }
    }
}


ul.undecorated {
    list-style-type: none;
    margin-bottom: 5rem;
    padding-left: 0;

    li {
        list-style-type: none;
    }
}




address {
    ul {
        padding-left: 2rem;
        list-style-type: none;

        li {
            margin-bottom: 0.2rem;

            .fa {
                width: 3rem;
            }

            h2, h3 {
                margin-left: 0;
            }
        }
    }
}
