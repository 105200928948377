﻿@import "variables";
$spinnerSize: 60;


.page-spinner {
    .fa-stack {
        display: block;
        width: 100%;
        text-align: center;
        margin: 2rem auto;
        font-size: 3rem;
    }
}

.spinner {
    .fa-lg {
        display: flex;
        margin-left: 1rem;
    }

    .fa-stack-1x {
        flex: 1 1 0%;
        color: theme-color('primary');
        transform: rotate(120deg);
        animation: inner 2s infinite linear;
    }

    .fa-stack-2x {
        flex: 1 1 0%;
        color: theme-color('info');
        animation: outer 5s infinite linear;
    }
}

@keyframes inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes outer {
    0% {
        color: theme-color('info');
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    30% {
        color: theme-color('info');
    }
    75% {
        color: theme-color('success');
    }

    100% {
        color: theme-color('info');
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
