@import "variables"; 
@import "../../../libs/bootstrap-v4/4.3.1/scss/nav";


.nav-tabs {
    margin-top: 2rem;

    .active {
        box-shadow: rgba(0,0,0, 0.1) -1px 2px;
    }

    @media (hover:hover) {
        .nav-link:not(.active):hover {
                background-color: $gray-300;
        }
    }
}