﻿@import "variables";
@import "../../../libs/bootstrap-v4/4.3.1/scss/forms";



.ui-hint-required {
    font-size: $font-size-sm;
    float: right;

    span {
        background-color: rgba(theme-color('danger'), 0.1);
        padding: 0.2rem 0.5rem;
        border: $input-border-width solid $input-border-color;
    }
}


textarea {
    resize: vertical;
}

.form-control:focus {
    //TODO  border-color: $btn-info-bg;
    outline: 0;
    //TODO  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px opacify($btn-info-bg, 0.6);
    //TODO  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px opacify($btn-info-bg, 0.6);
}


.form-group {
    label {
        span {
            color: #e24747
        }
    }
}


input[type=number] {
    padding-right: 0;
}


form.was-validated {
    input:invalid.error, select:invalid.error {
        border: $input-border-width solid $input-border-color;
        border-bottom: solid 1px theme-color('danger');
    }

    .form-control.error {
        @extend .form-control:invalid;
    }
}

.form-control.is-valid, .was-validated .form-control:valid {
    border: $input-border-width solid $input-border-color;
    //border-bottom: solid 1px theme-color('success');
    background-image: none;
}

/*input:valid, select:valid {
    background-color: rgba(theme-color('success'), 0.1);
}*/

.form-control.required:not(:focus),
input:invalid, textarea:invalid, select:invalid, // browser selectors
input.invalid, textarea.invalid, select.invalid // jQueryValidation selectors
{
    background-color: rgba(theme-color('danger'), 0.1);
}


label.error {
    display: block;
    clear: left;
    color: theme-color('danger');
    margin-top: 0.2rem;
}

.form-group.switched {

    & > label {
        margin-left: 6rem;

        & > .bsw {
            margin-left: -5.5rem;
            margin-right: 1.2rem;
        }

        input[type=checkbox] {
            margin-left: -4rem;
            margin-right: 2.3rem;
        }
    }
}
