﻿@import "variables";


.pricing {
    .from {
        display: block;
        font-size: $font-size-sm;
    }

    .price {
        font-size: $font-size-lg;
        color: $gray-800;
    }

    .cycle {
        font-size: $font-size-sm;
        color: $text-muted;
        line-height: 1;
    }
}


.pricing .save, .price-save {
    color: theme-color('success');
    font-weight: bold;
}


.pricing .offer, .sales-offer {
    font-size: $font-size-sm;
    padding: 0.1rem 0.5rem;
    color: #fff;
    background-color: theme-color('success');
}