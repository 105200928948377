@include exports( "popup/theme" ) {

    .k-popup {
        @include appearance( popup );
        @include border-radius( $border-radius );
        margin: $dropdown-spacer 0 0 0;

        .k-header,
        .k-group-header {
            color: $dropdown-header-color;
            font-weight: 600;
            font-size: $font-size-sm;
        }

        &.k-column-menu {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .k-popup > .k-group-header,
    .k-popup > .k-virtual-wrap > .k-group-header {
        border-bottom-color: $border-color;
    }
    .k-popup .k-group-header + div > .k-list > .k-item.k-first::before {
        border-top-color: $border-color;
    }
}

@include exports( "list/theme" ) {

    .k-list {}

    .k-list .k-item {
        transition: all .2s ease; // sass-lint:disable-block no-transition-all
    }

    .k-list .k-item:hover,
    .k-list .k-item.k-state-hover {
        @include appearance( hovered-list-item );
    }

    .k-list .k-item.k-state-selected {
        @include appearance( selected-list-item );
    }

    .k-list .k-item:focus,
    .k-list .k-item.k-state-focused {
        box-shadow: inset 0 0 0 3px rgba($list-item-text, .15);

        &:hover,
        &.k-state-hover {
            box-shadow: inset 0 0 0 3px rgba($list-item-hovered-text, .15);
        }

        &.k-state-selected {
            box-shadow: inset 0 0 0 3px rgba($list-item-selected-text, .15);
        }
    }

}


@include exports( "listcontainer/theme" ) {


    .k-list-container {

        .k-list-optionlabel {
            border-bottom-color: $input-border;
        }

        // Button normal state
        .k-button {}


        // Button hover state
        .k-button:hover,
        .k-button.k-state-hover {
            @include appearance( hovered-list-item );
        }

        // Button pressed state
        .k-button:active,
        .k-button.k-state-active {
            @include appearance( selected-list-item );
        }


        // Button focus state
        .k-button:focus,
        .k-button.k-state-focused {
            box-shadow: inset 0 0 0 3px rgba($list-item-text, .15);

            &:hover,
            &.k-state-hover {
                box-shadow: inset 0 0 0 3px rgba($list-item-hovered-text, .15);
            }

            &:active,
            &.k-state-active {
                box-shadow: inset 0 0 0 3px rgba($list-item-selected-text, .15);
            }
        }

    }

}