﻿@import "variables";

:root {
    font-size: 10px;
}


// Powered by SmarterTrack on live chat
.st_CopyRight {
    display: none;
}